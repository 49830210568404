import Seo from 'modules/app/components/Seo';
import React from 'react';
import { NotFound } from 'modules/notFound';
import { PageProps } from 'gatsby';
import useSiteMetadata from 'modules/app/graphql/useSiteMetadata';

const NotFoundPage: React.FC<PageProps> = () => {
  const { siteUrl } = useSiteMetadata();

  if (typeof window === 'undefined') {
    return null;
  }

  return (
    <>
      <Seo
        title="Roango - Page not found"
        url="https://roango.com"
        image={`${siteUrl}/roango-share-image.png`}
      />
      <section>
        <NotFound />
      </section>
    </>
  );
};

export default NotFoundPage;
